/*module scripts import*/
import 'jquery'
import 'bootstrap'
import 'select2'

/*assets import*/
function requireAll(r) {
    r.keys().forEach(r)
}

require('../css/app.scss') //main scss file
requireAll(require.context('../js', true, /\.js$/)) //all scripts from assets folder
requireAll(require.context('../images', true, /\.(png|jpg|gif|svg)$/)) //all images from assets folder
requireAll(require.context('../../patterns', true, /\.js$/)); //all components scripts
requireAll(require.context('../../pages', true, /\.js$/)); //all pages scripts
