import Mustache from 'mustache'
import data from './prodoshop-orders-model.json'

const template = $('#prodoshop-orders-model-page-template')
const content = $('#prodoshop-orders-model-page-content')

if (template.length) {
    content.html(Mustache.render(template.html(), data))
    template.remove()
}

// Recsys

var form = $('.js-prodoshop-clicks-model-form');
var inputs = "";

initialise_form();

form.on('change', function(event){
    if (event.isTrigger !== 3) {
        $(".product-list").html('');
        $(".select2-selection__choice__display").each(function (index, item) {
            var inputProductUrl = $(item).html();
            inputs = '';
            inputs += inputProductUrl + ',';
            $.ajax({
                data: {
                    product_url: inputProductUrl
                },
                method: "get",
                url: "https://recsysdemo.ui42.sk/get-product-html",
                success: function (data) {
                    var productTitle = $(data).find('h1').text();
                    var productImg = $(data).find('.img-responsive').attr('src');

                    productImg = 'https://prodoshop.sk' + productImg;

                    $(".js-reccommendation-experiment-input-product-list > ul").append("<li class=\"product-list__item\">\n" +
                        "    <div class=\"product-card\">\n" +
                        "        <a href=\"https://www.dedoles.sk/vesele-ponozky-cokolada\" target=\"_blank\" class=\"product-card__inner\">\n" +
                        "                <div class=\"product-card__img-wrapper\">\n" +
                        "                    <img src=\"" + productImg + "\" alt=\"Veselé ponožky Čokoláda\" class=\"product-card__img\">\n" +
                        "                </div>\n" +
                        "            <div class=\"product-card__content-wrapper\">\n" +
                        "                <h4 class=\"product-card__title\">\n" +
                        productTitle +
                        "                </h4>\n" +
                        "            </div>\n" +
                        "        </a>\n" +
                        "    </div>\n" +
                        "\n" +
                        "            </li>");

                }
            });
        });
    } else if (event.isTrigger === 3) {
        $(".product-list").html('');
    }
});

form.on('submit', function(event){
    event.stopPropagation();
    event.preventDefault();
    showAnimation();
    renderResults();
});

function initialise_form() {
    $(".product-list").html('');
}

function renderResults(){

    inputs = inputs.trim().split(',');

    var inputUrls = format_urls(inputs);



    var requestData = {
        "product_urls": inputUrls,
        "input_type": "urls",
        "instance" : "prodoshop"
    };

    var apiUrl = 'https://recsysd.ui42.sk/api/v1/prediction/orders';


    console.log(apiUrl);

    $.ajax({
        url: apiUrl,
        data: JSON.stringify(requestData),
        type: "POST",
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        success: function(data){

            console.log("success");
            console.log(data);
            var parsedData = data;

            $('.js-reccommendation-experiment-results-product-list-wrapper > ul').html('');

            for (var key in parsedData) {
                if (parsedData.hasOwnProperty(key)) {
                    var id = parsedData[key].split(':')[0];
                    var productUrl = parsedData[key].split(':')[1];

                    $.ajax({
                        data: {
                            product_url: productUrl
                        },
                        method: "get",
                        url: "https://recsysdemo.ui42.sk/get-product-html",
                        success: function (data) {
                            var productTitle = $(data).find('h1').text();
                            var productImg = $(data).find('.img-responsive').attr('src');

                            productImg = 'https://prodoshop.sk' + productImg;

                            $(".js-reccommendation-experiment-results-product-list-wrapper > ul").append("<li class=\"product-list__item\">\n" +
                                "    <div class=\"product-card\">\n" +
                                "        <a href=\"https://www.dedoles.sk/vesele-ponozky-cokolada\" target=\"_blank\" class=\"product-card__inner\">\n" +
                                "                <div class=\"product-card__img-wrapper\">\n" +
                                "                    <img src=\"" + productImg + "\" alt=\"Prodoshop\" class=\"product-card__img\">\n" +
                                "                </div>\n" +
                                "            <div class=\"product-card__content-wrapper\">\n" +
                                "                <h4 class=\"product-card__title\">\n" +
                                productTitle +
                                "                </h4>\n" +
                                "            </div>\n" +
                                "        </a>\n" +
                                "    </div>\n" +
                                "\n" +
                                "            </li>");

                        }
                    });
                }
            }

        },
        error: function(error){
            console.log("error");
            console.log(error);
        }
    });




}

function showAnimation(){
    content.find('.js-page-body').addClass('loading').delay(1250).queue(function (next) {
        $(this).removeClass('loading')
        next()
    });
}

function format_urls(urls) {
    var cleanUrls = [];

    urls.forEach(function(url, index, array) {
        if (url.startsWith('http')) {
            var urlObj = new URL(url);
            cleanUrls.push(urlObj.host.replace('www.', '') + urlObj.pathname);
        } else {
            var formattedUrl = url.replace('www.', '');
            if (formattedUrl !== '') {
                cleanUrls.push(formattedUrl);
            }
        }
    });

    return cleanUrls;
}

function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return typeof sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
    return false;
}
