import Mustache from 'mustache'
import data from './prodoshop-transformer-model.json'

const template = $('#prodosop-transformer-model-page-template')
const content = $('#prodoshop-transformer-model-page-content')

if (template.length) {
    content.html(Mustache.render(template.html(), data))
    template.remove()
}

// Recsys

var form = $('.js-reccommendation-prodoshop-experiment-form');
var inputs = "";

initialise_form();

form.on('change', function(event) {
    if (event.isTrigger !== 3) {
        $(".product-list").html('');
        inputs = '';
        $(".select2-selection__choice__display").each(function(index, item) {
            var inputProductUrl = $(item).html();
            inputs += inputProductUrl + ',';
        });
    } else if (event.isTrigger === 3) {
        $(".product-list").html('');
    }
});


form.on('submit', function(event) {
    event.stopPropagation();
    event.preventDefault();
    showAnimation();
    renderResults();
});

function initialise_form() {
    $(".product-list").html('');
}

function renderResults() {
    inputs = inputs.trim();
    $.ajax({
        data: {
            products_urls: inputs
        },
        method: "get",
        url: "https://recsys-prodoshop.ui42.sk/recommend-demo?",
        success: function(data) {
            console.log("success");
            var parsedData = data;
            $('.js-reccommendation-experiment-results-product-list-wrapper > ul').html('');

            for (var key in parsedData) {
                var product = parsedData[key];
                var imgLink = product['product_img_url']

                $(".js-reccommendation-experiment-results-product-list-wrapper > ul").append("<li class=\"product-list__item\">\n" +
                    "    <div class=\"product-card\">\n" +
                    "        <a href=\"" + product['product_url'] + "\" target=\"_blank\" class=\"product-card__inner\">\n" +
                    "                <div class=\"product-card__img-wrapper\">\n" +
                    "                    <img src=\"" + product['product_image_url'] + "\" alt=\"Prodoshop\" class=\"product-card__img\">\n" +
                    "                </div>\n" +
                    "            <div class=\"product-card__content-wrapper\">\n" +
                    "                <h4 class=\"product-card__title\">\n" +
                    product['product_title'] +
                    "                </h4>\n" +
                    "            </div>\n" +
                    "        </a>\n" +
                    "    </div>\n" +
                    "\n" +
                    "            </li>");
            }
            $([document.documentElement, document.body]).animate({
                scrollTop: $(".js-reccommendation-experiment-results").offset().top
            }, 2000);
        },
        error: function(error) {
            console.log("error");
            console.log(error);
        }
    });
}

function showAnimation() {
    content.find('.js-page-body').addClass('loading').delay(1250).queue(function(next) {
        $(this).removeClass('loading')
        next()
    });
}

function format_urls(urls) {
    var cleanUrls = [];

    urls.forEach(function(url, index, array) {
        if (url.startsWith('http')) {
            var urlObj = new URL(url);
            cleanUrls.push(urlObj.host.replace('www.', '') + urlObj.pathname);
        } else {
            var formattedUrl = url.replace('www.', '');
            if (formattedUrl !== '') {
                cleanUrls.push(formattedUrl);
            }
        }
    });

    return cleanUrls;
}

function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return typeof sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
    return false;
}