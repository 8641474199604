import Mustache from 'mustache'
import data from './chatbot.json'

const template = $('#chatbot-index-page-template')
const content = $('#chatbot-index-page-content')

let chatbotExitImg = `https://chatbot.ui42.sk/img/exit.svg`
let chatbotCloseImg = `https://chatbot.ui42.sk/img/close-chatbot-icon.svg`
let chatbotStartImg = `https://chatbot.ui42.sk/img/dedoles-chat-start.gif`
let chatbotContent, chatbotFrame;

let chatbotUrl = `https://chatbot.ui42.sk/dedoles/sk/`
if (template.length) {
    content.html(Mustache.render(template.html(), data))
    template.remove()
}

$('#dedolesSK').click(function() {
    chatbotUrl = `https://chatbot.ui42.sk/dedoles/sk/`;
    displayChatbot();
    return false;
});

$('#dedolesCZ').click(function() {
    chatbotUrl = `https://chatbot.ui42.sk/dedoles/cz/`;
    displayChatbot();
    return false;
});

$('#dedolesDE').click(function() {
    chatbotUrl = `https://chatbot.ui42.sk/dedoles/de/`;
    displayChatbot();
    return false;
});

$('#dedolesAT').click(function() {
    chatbotUrl = `https://chatbot.ui42.sk/dedoles/at/`;
    displayChatbot();
    return false;
});

$('#dedolesPL').click(function() {
    chatbotUrl = `https://chatbot.ui42.sk/dedoles/pl/`;
    displayChatbot();
    return false;
});



if (typeof FontFace != "undefined") {
    let dokosansneue = new FontFace('dokosansneue-bold', 'url(https://chatbot.ui42.sk/fonts/DokoSansNeue-Regular.otf)');
    dokosansneue.load().then(function(loaded_face) {
        document.fonts.add(loaded_face);
        generateChatbotDelay()
    }).catch(function(error) {
        setTimeout(() => {
            chatbotGenerate()
        }, 1)
    });
}

const generateChatbotDelay = () => {
    if (localStorage.getItem("chatbot-helper-time")) {
        let chatbotNow = new Date();
        let chatbotLocalTime = new Date(localStorage.getItem("chatbot-start-time"))
        if (chatbotSameDay(chatbotNow, chatbotLocalTime)) {
            chatbotGenerate()
        } else {
            setTimeout(() => {
                chatbotGenerate()
            }, 1)
        }
    } else {
        setTimeout(() => {
            chatbotGenerate()
        }, 1)
    }
}

const chatbotGenerate = () => {
    let chatbotStyle = document.createElement('style');
    chatbotStyle.innerHTML = `.display-none{display:none}.chatbot-wrapper{font-family:dokosansneue-bold,sans-serif;position:relative}.chatbot-open{position:fixed;margin:0;bottom:110px;left:10px;text-decoration:none;display:block;width:60px;height:60px;border-radius:12px;box-shadow:none;background-image:url(${chatbotStartImg});background-repeat:no-repeat;background-size:45px;background-position:center;background-color:#e1e0e0;margin-bottom:10px;margin-top:10px;z-index:1000}.chatbot-open__shadow{box-shadow:0 6px 12px rgba(0,0,0,.15)}.chatbot-actions{position:fixed;left:10px;bottom:115px;width:calc(100% - 20px);max-width:500px;background-color:#e1e0e0;display:flex;flex-direction:column;justify-content:center;align-items:center;box-shadow:0 6px 12px rgba(0,0,0,.2);border-radius:12px;z-index:999}.chatbot-actions p{margin:0;padding:15px 40px;font-size:16px;color:#000;font-weight:bolder;text-align:center}.chatbot-close{display:block;position:absolute;top:15px;right:15px;width:25px;height:25px;background-image:url(${chatbotCloseImg});background-repeat:no-repeat;background-size:contain;background-position:center}.chatbot-launch{cursor:pointer;width:100%;text-align:center;font-weight:700;letter-spacing:.2px;padding:15px 0;border:none;color:#00aab3;text-decoration:none}#chatbot-content{position:fixed;left:10px;bottom:20px;width:360px;height:600px;border-radius:12px;box-shadow:0 6px 12px rgba(0,0,0,.2);overflow:hidden;border:none;z-index:4500}@media (max-width:600px){#chatbot-content{bottom:0;left:0;width:100%;height:100%;border-radius:0;box-shadow:none}}@media (max-height:640px){#chatbot-content{left:0;bottom:0;height:100%;border-radius:0;box-shadow:none}}#chatbot-content__frame{width:100%;height:100%;border:none}.minimize-chat{display:inline-block;position:absolute;top:2px;right:0;background:url(${chatbotExitImg}) no-repeat center center;background-size:33px;height:60px;width:50px}`
    document.body.appendChild(chatbotStyle);

    let chatbotButtonShadow = ``;

    //load html element
    var chatbotBody = `
        <div id="chatbot-content" style="display:none">
            <a id="chatbot-exit" onclick="hideChatbot(event)" class="minimize-chat"></a>
            <iframe id="chatbot-content__frame"></iframe>
        </div>
    `
    var chatbotDiv = document.createElement("div");
    chatbotDiv.classList.add("chatbot-wrapper")
    chatbotDiv.innerHTML = chatbotBody;
    document.body.appendChild(chatbotDiv);

    chatbotContent = document.getElementById('chatbot-content')
    chatbotFrame = document.getElementById('chatbot-content__frame')
}


const displayChatbot = () => {
    if (chatbotContent.style.display === "none") {
        chatbotContent.style.display = "block"
        if (chatbotFrame.src !== chatbotUrl) {
            chatbotFrame.src = chatbotUrl
        }
    }
}

const hideChatbot = () => {
    if (chatbotContent.style.display !== "none") {
        chatbotContent.style.display = "none"
    }
}

window.hideChatbot = hideChatbot;

const chatbotSameDay = (date1, date2) => {
    return date1.getFullYear() == date2.getFullYear() && date1.getMonth() == date2.getMonth() && date1.getDate() == date2.getDate();
}