import Mustache from 'mustache'
import data from './dedoles-recommendations.json'

const template = $('#dedoles-recommendations-page-template')
const content = $('#dedoles-recommendations-page-content')

if (template.length) {
    content.html(Mustache.render(template.html(), data))
    template.remove()
}


