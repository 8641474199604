import Mustache from 'mustache'
import data from './experiment-results.json'

const template = $('#recommendation-experiment-results-page-template')
const content = $('#recommendation-experiment-results-page-content')

if (template.length) {
    content.html(Mustache.render(template.html(), data))
    template.remove()
}

$(function () {
    content.find('.js-page-body').addClass('loading').delay(2500).queue(function (next) {
        $(this).removeClass('loading')
        next()
    })
})
